import React from "react";
import ContactSection from "~sections/contact/ContactOne/ContactSection";
import FooterOne from "~sections/marketing/FooterOne"
import { PageWrapper } from "~components/Core";

const header = {
  headerClasses: "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true
  // customLogo: Images.HeaderLogo,

}

export default function contactOne() {
  return (

    <PageWrapper headerConfig={header}>
      <div className="mt-nav">
        <ContactSection/>
        <FooterOne />
      </div>
    </PageWrapper>
  ) 
}
